import {
  Container,
  Text,
  Box,
  Button,
  Center,
  Input,
  Checkbox,
  InputGroup,
  InputLeftAddon,
  FormControl,
  FormErrorMessage,
  useToast,
  Flex,
  Spacer,
  Divider,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { useVoiceprintStore } from "../store";
import { useState, useEffect } from "react";
import { BASE_URL } from "../constants/api";

import { AnimatePresence, motion } from "framer-motion";
import CallStart from "./CallStart";

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const Details = ({
  accessToken,
  setProgress,
  progress,
  stream_uid,
  ...props
}) => {
  const { setCurrentStep, setStreamId, setExternalId, setName } =
    useVoiceprintStore();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    control,
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [callInterval, setCallInterval] = useState(null);
  const [buttonInterval, setButtonInterval] = useState(40);
  const [details, setDetails] = useState(undefined);
  const [callInitiated, setCallInitiated] = useState(false);
  const [callStarted, setCallStarted] = useState(false);
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const toast = useToast();

  useEffect(() => {
    if (callStarted === true) {
      if (callInterval === null) {
        setCallInterval(
          setInterval(() => {
            axios
              .get(`${BASE_URL}/stream/http/speech/${stream_uid}`)
              .then((res) => {
                if (res.status === 200) {
                  console.log(
                    "speech duration------------>",
                    res.data.speech_duration
                  );
                  setProgress(res.data.speech_duration);
                }
              })
              .catch((err) => console.error(err));
          }, 1000)
        );
      }
    }
  }, [callStarted, callInterval, progress]);

  // if (callStarted === true) {
  //   if (callInterval === null) {
  //     setCallInterval(
  //       setInterval(() => {
  //         axios
  //           .get(`${BASE_URL}/stream/http/speech/${stream_uid}`)
  //           .then(res => {
  //             if (res.status === 200) {
  //               console.log("speech duration------------>",res.data.speech_duration);
  //               setProgress(res.data.speech_duration);
  //             }
  //           })
  //           .catch(err => console.error(err));
  //       }, 1000)
  //     );
  //   }
  // }

  // const tempHandle = () => setCurrentStep("callStarted"); // Here we go to next step

  const handleCallClick = async (values) => {
    let counter = 0;
    let buttonCounter = 0;

    clearErrors();
    setUsername(values.name);
    setPhoneNumber(values.external_id);
    console.log(values);
    setIsLoading(true);
    setCallInitiated(true);
    //setCurrentStep("callStarted");

    if (!values.external_id.startsWith("+"))
      values.external_id = "+" + values.external_id;

    try {
      toast({
        title: "Initiating New Call",
        description: "Please wait while we initiate a call to your number.",
        status: "loading",
        duration: 5000,
        isClosable: true,
      });

      const res = await axios.post(`${BASE_URL}/initiate_call/enroll`, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (res.status === 200) {
        let call_interval = null;
        let button_interval = null;

        if (!button_interval) {
          button_interval = setInterval(() => {
            buttonCounter += 1000;
            setButtonInterval((prev) => prev - 1);
            if (buttonCounter >= 40000) {
              buttonCounter = 0;
              clearInterval(button_interval);
              button_interval = null;
              setButtonInterval(40);
              setIsLoading(false);
            }
          }, 1000);
        }

        if (!call_interval) {
          call_interval = setInterval(() => {
            clearErrors();
            counter += 3000;
            console.log(counter);

            if (counter >= 40000) {
              toast({
                title: "Trying Again.",
                description:
                  "It seems like you did not receive or attend the call. Let's try that again",
                status: "info",
                duration: 3000,
                isClosable: true,
              });

              counter = 0;
              clearInterval(call_interval);
              call_interval = null;
              handleCallClick(values);
              // return;
            }

            axios
              .get(
                `${BASE_URL}/check_call_status?external_uid=${values.external_id}`,
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              )
              .then((res2) => {
                if (res2.status === 200 && res2.data.active === true) {
                  console.log(res2.data);

                  toast({
                    title: "Call Initiated",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  });

                  setIsLoading(false);
                  setCallStarted(true);
                  setStreamId(res2.data.stream_uid);
                  setExternalId(values.external_id);
                  setName(values.name);
                  // Here we go to next step
                  clearInterval(call_interval);
                  call_interval = null;
                }
              })
              .catch((err) => {
                setIsLoading(false);
                setError("serverError", {
                  type: "custom",
                  message: "Trying again",
                });
                console.error(err);
              });
          }, 3000);
        }
      } else {
        setIsLoading(false);
        setError("serverError", {
          type: "custom",
          message: "Something went wrong. Please try again.",
        });
      }
    } catch (err) {
      setDetails("Voice Print with given number already exists");
      setError("serverError", { type: "custom", message: err.toString() });
      setIsLoading(false);
    }
  };

  return (
    <>
      {callInitiated === false ? (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, x: 200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -200 }}
            style={{ width: "100%" }}
          >
            <Text color="gray.400" fontSize="lg">
              Enter your details
            </Text>
            <form onSubmit={handleSubmit(handleCallClick)}>
              <FormControl isInvalid={errors.name}>
                <Input
                  placeholder="Name"
                  w="100%"
                  mt={4}
                  {...register("name", { required: "Name is required" })}
                />
                {errors.name && (
                  <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={errors.external_id}>
                <Controller
                  control={control}
                  name="external_id"
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <PhoneInput
                      {...field}
                      containerStyle={{ marginTop: "8px" }}
                      inputStyle={{ width: "100%" }}
                      isValid={(value, country) => {
                        const pakRegex =
                          /^((\+92)?(0092)?(92)?(0)?)(3)([0-9]{9})$/gm;
                        const canRegex =
                          /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
                        if (
                          (country.name === "Pakistan" &&
                            value.match(pakRegex)) ||
                          (country.name === "Canada" && value.match(canRegex))
                        ) {
                          // if (errors.external_id) clearErrors("external_id");

                          return true;
                        } else {
                          // setError("external_id", {
                          //   type: "validate",
                          //   message: "Invalid Phone Number"
                          // });
                          return false;
                        }
                      }}
                      inputProps={{
                        ref,
                        required: true,
                        autoFocus: true,
                      }}
                      country={"pk"}
                      onlyCountries={["pk", "ca", "ae", "sa"]}
                      countryCodeEditable={false}
                      specialLabel={"Mobile Number"}
                    />
                  )}
                />
                {/* </InputGroup> */}
                {errors.external_id && (
                  <FormErrorMessage>
                    {JSON.stringify(errors.external_id)}
                    {errors.external_id.message || "Invalid Phone Number"}
                  </FormErrorMessage>
                )}
              </FormControl>

              <Text fontSize="sm" color="gray.600" textAlign="left" mt={4}>
                Please prepare your phone device, click the call button, and
                wait for the call to start the enrollment.
              </Text>
              {isLoading === false ? (
                <Button
                  size="lg"
                  mt="10"
                  w="100%"
                  color="white"
                  bgColor="black"
                  type="submit"
                  isLoading={isLoading}
                >
                  Call Me
                </Button>
              ) : (
                <Button
                  size="lg"
                  mt="10"
                  w="100%"
                  color="white"
                  bgColor="black"
                  disabled
                >
                  Trying again in {buttonInterval} seconds
                </Button>
              )}

              {errors.serverError && (
                <Text color="red" mt={4} textAlign="left">
                  {details}
                </Text>
              )}
            </form>
          </motion.div>
        </AnimatePresence>
      ) : (
        <Flex gap="50px">
          <AnimatePresence>
            <Flex flexDir="column" w="50%" h="full" justify="space-evenly">
              <motion.div
                initial={{ opacity: 0, x: 200 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -200 }}
                style={{ width: "100%" }}
              >
                <Text color="gray.400" fontSize="lg" pb={5}>
                  User details
                </Text>
                <form onSubmit={handleSubmit(handleCallClick)}>
                  <Flex py={2}>
                    <Text color="#1b1c1e" fontSize="md">
                      Name
                    </Text>
                    <Spacer />
                    <Text color="#1b1c1e" fontSize="md">
                      {username}
                    </Text>
                  </Flex>
                  <Flex py={2}>
                    <Text color="#1b1c1e" fontSize="md">
                      Phone Number
                    </Text>
                    <Spacer />
                    <Text color="#1b1c1e" fontSize="md">
                      {phoneNumber}
                    </Text>
                  </Flex>
                  <Text fontSize="sm" color="gray.600" textAlign="left" mt={4}>
                    Please prepare your phone device, click the call button, and
                    wait for the call to start the enrollment.
                  </Text>
                  {isLoading === false ? (
                    <Button
                      size="lg"
                      mt="10"
                      w="100%"
                      color="white"
                      bgColor="black"
                      type="submit"
                      isLoading={isLoading}
                      disabled
                    >
                      Call Me
                    </Button>
                  ) : (
                    <Button
                      size="lg"
                      mt="10"
                      w="100%"
                      color="white"
                      bgColor="black"
                      disabled
                    >
                      Trying again in {buttonInterval} seconds
                    </Button>
                  )}

                  {errors.serverError && (
                    <Text color="red" mt={4} textAlign="left">
                      {details}
                    </Text>
                  )}
                </form>
              </motion.div>
            </Flex>
          </AnimatePresence>
          {/* <Spacer /> */}
          <Divider orientation="vertical" h="300px" />
          <Box w="50%">
            <CallStart
              setProgress={setProgress}
              progress={progress}
              speech_interval={callInterval}
              accessToken={accessToken}
              callStarted={callStarted}
            />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default Details;
