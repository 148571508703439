/**
 * The CallStart function is a component that displays a circular progress bar and a button for ending
 * a call, and initiates a voice enrollment process when the progress bar reaches a certain point.
 * @returns This component is returning a circular progress bar with a label indicating the duration of
 * speech, a button to end the call, and a text indicating to continue speaking until the circle turns
 * green. It also includes functionality to make an API call to enroll the user's voiceprint and
 * display a toast message indicating the success or failure of the enrollment.
 */
import {
  Text,
  Button,
  CircularProgress,
  CircularProgressLabel,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useVoiceprintStore } from "../store";
import { useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import { BASE_URL } from "../constants/api";

// Animate continue speaking like Uber places order (fake transition)

// Circular progress bar voice fake animation

const CallStart = ({
  progress,
  setProgress,
  callStarted,
  accessToken,
  speech_interval,
}) => {
  const {
    currentStep,
    setCurrentStep,
    stream_uid,
    external_id,
    speech_duration,
    setSpeechDuration,
  } = useVoiceprintStore();

  // useEffect(() => {
  //   console.log(speech_interval);
  //   if (speech_interval === null) {
  //     speech_interval = setInterval(() => {
  //       axios
  //         .get(`${BASE_URL}/stream/http/speech/${stream_uid}`)
  //         .then(res => {
  //           if (res.status === 200) {
  //             console.log(res.data);
  //             setProgress(res.data.speech_duration);
  //           }
  //         })
  //         .catch(err => console.error(err));
  //     }, 1000);
  //   }

  //   return () => {
  //     if (speech_interval) clearInterval(speech_interval);
  //   };
  // }, []);

  const toast = useToast();
  const registeringToastIdRef = useRef();

  const enrollRequest = () => {
    axios
      .get(`${BASE_URL}/stream/http/${stream_uid}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        console.log("Voice Enroll Success", res.data);
        if (res.status === 200) {
          if (registeringToastIdRef.current) {
            toast.close(registeringToastIdRef.current);
          }

          toast({
            title: "Ended Your Call",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setSpeechDuration(res.data.speech_duration);
          setCurrentStep("new");
        }
      })
      .catch((err) => {
        console.error("Error Closing Stream ", err);
      });
  };

  // useEffect(() => {
  //   console.log("progress----------->", progress);
  //   if (currentStep === "details" && progress >= 20) {
  //     if (speech_interval) {
  //       clearInterval(speech_interval);
  //       speech_interval = null;
  //     }
  //     enrollRequest();
  //   }
  // }, [progress]);

  // Double Check
  const handleEndCall = (e) => {
    console.log(speech_interval);
    if (speech_interval) {
      clearInterval(speech_interval);
      speech_interval = null;
    }
    enrollRequest();
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, x: 200 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -200 }}
        style={{
          width: "100%",
          position: "relative",
          // borderWidth: 1,
          height: "100%",
        }}
      >
        <Text fontSize="lg" color="gray.500" textAlign="center">
          {callStarted ? "Continue Speaking" : "Please Wait..."}
        </Text>
        <VStack
          h="80%"
          // spacing="22px"

          mt={5}
          justify="space-between"
        >
          <Text fontSize="md" textColor="black">
            {callStarted ? "Connected" : `Dialing ${external_id ?? ""}`}
          </Text>
          <div
            className="pulsating-circle"
            // value={progress * 5}
            // pulse
            // color="green.300"
            // size="200px"
            // thickness="4px"
            // alignSelf="center"
            // capIsRound
            // valueText="xsaa"
          >
            {/* <CircularProgressLabel fontSize="sm">
              <Text>00:{progress}</Text>
              <Text color="gray.400">of speaking</Text>
            </CircularProgressLabel> */}
          </div>
          <div>
            <Button
              size="lg"
              w="100%"
              color="white"
              bgColor="black"
              onClick={handleEndCall}
            >
              Make New Call
            </Button>
            <Text size="xs" color="slategray" mt={1}>
              Note: The current call will be terminated
            </Text>
          </div>
        </VStack>
      </motion.div>
    </AnimatePresence>
  );
};

export default CallStart;
