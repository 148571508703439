import { Container, Text, Box, Button, Center, Flex } from "@chakra-ui/react";
import AddNew from "../components/AddNew";
import Details from "../components/Details";
import CallStart from "../components/CallStart";

import { useState, useEffect, useRef } from "react";
import { useVoiceprintStore } from "../store";
import { BASE_URL } from "../constants/api";
import axios from "axios";

const Home = ({ accessToken, ...props }) => {
  // Initiate Call - Call Me - POST - Twilio + 1 second interval request to see if request.200 => Call Started. If not 200 then call not started
  // After 200 returned => State Changed to Continue Speaking. 1 second interval to check speech duration. (Time Elapsed). Once it reaches 20 second
  // Send Enroll Call.
  // When user clicks end call, stream ended (GET) and redirect to Voice Summary
  // Verify => Initiate call again with different end point. Same thing as first time but different params
  // After 5 secs => Verified and Done. On Done. Redirect back to first screen and clear local storage

  //   const [currentStep, setCurrentStep] = useState("new"); // new, details, callStarted, callSummary, verify

  const { currentStep, stream_uid } = useVoiceprintStore();

  const [callInterval, setCallInterval] = useState(null);
  const [verifyInterval, setVerifyInterval] = useState(null);
  const [progress, setProgress] = useState(0);

  const config = {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  };

  useEffect(() => {
    console.log(callInterval, verifyInterval);

    // setCurrentStep("verify");

    if (currentStep === "callStarted") {
      if (callInterval === null) {
        setCallInterval(
          setInterval(() => {
            axios
              .get(`${BASE_URL}/stream/http/speech/${stream_uid}`)
              .then((res) => {
                if (res.status === 200) {
                  console.log(res.data);
                  setProgress(res.data.speech_duration);
                }
              })
              .catch((err) => console.error(err));
          }, 1000)
        );
      }
    } else if (currentStep === "verify") {
      if (verifyInterval === null) {
        setVerifyInterval(
          setInterval(() => {
            axios
              .get(`${BASE_URL}/stream/http/speech/${stream_uid}`)
              .then((res) => {
                if (res.status === 200) {
                  console.log(res.data);
                  setProgress(res.data.speech_duration);
                }
              })
              .catch((err) => console.error(err));
          }, 1000)
        );
      }
    }

    return () => {
      clearInterval(callInterval);
      clearInterval(verifyInterval);
      // if (callInterval) {
      setCallInterval(null);
      // }
      // if (verifyInterval) {
      setVerifyInterval(null);
      // }
    };
  }, [currentStep]);

  return (
    <>
      {currentStep === "new" ? (
        <Flex
          flexDir="column"
          alignItems="flex-start"
          m="auto"
          borderWidth={1}
          borderRadius="10px"
          mt="10%"
          py="10"
          px="10px"
          w="30%"
          h="100%"
        >
          <AddNew accessToken={accessToken} setProgress={setProgress} />
        </Flex>
      ) : currentStep === "details" ? (
        <Flex
          flexDir="column"
          alignItems="flex-start"
          borderWidth={1}
          borderRadius="10px"
          m="auto"
          mt="5%"
          py="10"
          px="8"
          w="50%"
          h="50%"
        >
          <Details
            accessToken={accessToken}
            setProgress={setProgress}
            progress={progress}
            speech_interval={callInterval}
            setCallInterval={setCallInterval}
            stream_uid={stream_uid}
          />
        </Flex>
      ) : (
        <Container
          mt={100}
          h="70vh"
          alignItems="center"
          justifyItems="center"
          display="flex"
          flexDir="column"
          verticalAlign="middle"
        >
          <Text fontSize="3xl" fontWeight="normal" pb="5">
            Xiva Speech Bot
          </Text>
          <Flex
            flexDir="column"
            alignItems="flex-start"
            borderWidth={1}
            borderRadius="10px"
            m="auto"
            py="10"
            px="8"
            w="auto"
            h="auto"
          >
            {currentStep === "callStarted" ? (
              <CallStart
                setProgress={setProgress}
                progress={progress}
                speech_interval={callInterval}
                accessToken={accessToken}
              />
            ) : null}
            {/* <AddNew /> */}
            {/* <Details /> */}
            {/* <CallStart /> */}
            {/* <Summary /> */}
            {/* <Verified /> */}
          </Flex>
        </Container>
      )}
    </>
  );
};

export default Home;
