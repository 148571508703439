import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box, ChakraProvider, Container, Stack, Text } from "@chakra-ui/react";

import Home from "./pages/Home";
import Login from "./pages/Login";

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        {/* <div className="App"> */}
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>

        {/* </div> */}
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
