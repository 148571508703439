/**
 * The function `AddNew` displays information about enrolled voiceprints and allows the user to create
 * a new voiceprint.
 * @returns The component AddNew is being returned.
 */
import { Text, Box, Button, Flex } from "@chakra-ui/react";

import { useVoiceprintStore } from "../store";

const AddNew = ({ setProgress, accessToken }) => {
  const { setCurrentStep } = useVoiceprintStore();

  return (
    <Flex flexDir="column" alignItems="center" w="full">
      <Box px={5} w="100%" mb="8%">
        <Text color="#1b1c1e" fontSize="xl" textAlign="center">
          Xiva Speech Bot
        </Text>
      </Box>

      <Button
        size="lg"
        mt="10"
        w="60%"
        color="white"
        bgColor="black"
        onClick={() => setCurrentStep("details")}
      >
        Create a Voiceprint
      </Button>
    </Flex>
  );
};

export default AddNew;
