import React, { useState } from "react";

import {
  chakra,
  Box,
  Button,
  Flex,
  Stack,
  Input,
  InputGroup,
  Text,
  FormControl,
  FormErrorMessage,
  Heading,
  Image,
  Container,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import axios from "axios";
import { BASE_URL } from "../constants/api";
import qs from "qs";
import Home from "./Home";
import { useVoiceprintStore } from "../store";
// import { logo } from "/jazz.png";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  const { setCurrentStep } = useVoiceprintStore();

  // Other Hooks
  const {
    handleSubmit,
    register,
    clearErrors,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      const body = {
        username: values.username,
        password: values.password,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: qs.stringify(body),
        url: `${BASE_URL}/user/login`,
      };

      axios(options)
        .then((res) => {
          if (res.data) {
            console.log("Res----->", res.data);
            setCurrentStep("new");
            setAccessToken(res.data.access_token);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Login Failed: ", err);
          setError("serverError", {
            message: err?.response?.data?.detail || err.toString(),
          });
          setIsLoading(false);
        });
    } catch (err) {
      setError("serverError", {
        message: err?.response?.data?.detail || err.toString(),
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      {accessToken ? (
        <Home accessToken={accessToken} />
      ) : (
        <>
          <Flex
            minH={"92vh"}
            align={"center"}
            justify={"center"}
            bg="gray.50"
            flexDir="column"
          >
            <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
              <Stack align={"center"}>
                <Heading fontSize={"4xl"}>Sign in to your account</Heading>
                <Text fontSize={"lg"} color={"gray.600"}>
                  to begin the demo for Alara's Speech Bot
                </Text>
              </Stack>

              <Box rounded={"lg"} bg="white" boxShadow={"lg"} p={8}>
                <Stack spacing={4}>
                  <chakra.form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isInvalid={errors.username} mt={6}>
                      <Input
                        placeholder="Username"
                        {...register("username", {
                          required: "Username is required",
                        })}
                      />
                      {errors.username && (
                        <FormErrorMessage>
                          {errors.username.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl isInvalid={errors.password} mt={6}>
                      <InputGroup>
                        <Input
                          type="password"
                          placeholder="Password"
                          {...register("password", {
                            required: "Password is required",
                          })}
                        />
                      </InputGroup>
                      {errors.password && (
                        <FormErrorMessage>
                          {errors.password.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <Button
                      mt={10}
                      w="full"
                      type="submit"
                      bgColor="black"
                      color="white"
                      isLoading={isLoading}
                      onClick={() => clearErrors(["serverError"])}
                    >
                      Sign In
                    </Button>
                    {errors.serverError && (
                      <Text color="red" mt={4} textAlign="left">
                        {errors.serverError.message}
                      </Text>
                    )}
                  </chakra.form>
                </Stack>
              </Box>
              <Flex w="full" align="center" gap={5} justify="center">
                <Text color="slategray">Current Demonstration for</Text>{" "}
                <Image w={10} h={10} src="/kfc.png" />
              </Flex>
            </Stack>
          </Flex>
          <Box bg="gray.100" color="gray.700">
            <Box
              borderTopWidth={1}
              borderStyle={"solid"}
              borderColor={"gray.200"}
            >
              <Container
                as={Stack}
                maxW={"6xl"}
                py={4}
                spacing={4}
                justify={{ base: "center", md: "space-between" }}
                align={{ base: "center", md: "center" }}
              >
                <Text
                  textAlign="center"
                  color="gray.400"
                  letterSpacing="widest"
                >
                  © 2023 ISSM.AI | All rights reserved
                </Text>
              </Container>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Login;
