import create from "zustand";
import { persist } from "zustand/middleware";

let voiceprintStore = (set, get) => ({
  currentStep: "new",
  external_id: null, //phNumber
  name: null, // name
  stream_uid: null, // auto generated
  speech_duration: "0",
  setCurrentStep: newStep => set(state => ({ currentStep: newStep })),
  setName: newName => set(state => ({ name: newName })),
  setStreamId: suid => set(state => ({ stream_uid: suid })),
  setExternalId: eid => set(state => ({ external_id: eid })),
  setSpeechDuration: sd => set(state => ({ speech_duration: sd }))
});

voiceprintStore = persist(voiceprintStore, { name: "voiceprint" });

export const useVoiceprintStore = create(voiceprintStore);
